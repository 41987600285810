@import '../../styles/customMediaQueries.css';
@import '../../styles/propertySets.css';

.root {
}

/* heroContainer gives the height for SectionHero */
/* Safari has a bug with vw padding inside flexbox. Therefore we need an extra div (container) */
/* If you've lot of content for the hero, multiple lines of text, make sure to adjust min-heights for each media breakpoint accordingly */
.heroContainer {
  display: flex;
  flex-direction: column;
  min-height: 300px;
  height: 60.5vh;
  max-height: 600px;
  padding: 0;

  @media (--viewportMedium) {
    min-height: 500px;
    height: 70vh;
    max-height: none;
  }

  @media (--viewportLarge) {
    max-height: 800px;
    min-height: 600px;
    height: calc(70vh - var(--topbarHeightDesktop));
  }
}

.heroRoot {
  @apply --backgroundImage;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.hero {
  flex-grow: 1;
  justify-content: flex-end;
  padding-bottom: 32px;

  @media (--viewportMedium) {
    padding-bottom: 83px;
  }

  @media (--viewportLarge) {
    justify-content: center;
    padding-top: 60px;
  }
}

.sections {
  margin: 0;
  padding-top: 1px;
}

.section {
  overflow: auto;
}
.mainSection {
  width: 100%;
  /* margin: 100px 0; */
  
  position: relative;
}
.ellipsUp {
  position: absolute;
  right: 30px;
  margin-top:47px;
}
.ellipsDown {
  position: absolute;
  bottom: 30px;
  left: 30px;
}
.mainContainer {
  max-width: 1068px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 768px) {    
    margin-top: -1300px;
  }
}

.boxnew{
  background-color: #78264F!important;
}
.topTextContainer {
  width: 100%;
  background: url('./Pattern1.png');
  
  min-height: 256px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.headingLevel1{


/* H2 */

font-family: 'Source Sans Pro';
font-style: normal;
font-weight: 600;
font-size: 40px;
line-height: 50px;
text-align: center;

/* Ellipse 3 */

color: #2B2F68;
}

.myBtn{
  display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
padding: 6px 15px 7px;
gap: 10px;

width: 159px;
height: 32px;

/* Ellipse 2 */

background: #FFFFFF;
border-radius: 100px;
border-color: #FFFFFF;
color: #2B2F68;


}
.topText {
  max-width: 560px;
  
  margin: 0 auto;
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: var(--fontWeightSemiBold);
  font-size: 25px;
  line-height: 30px;
  text-align: center;
  color: var(--burgundy);
  padding: 0 20px;
  /* letter-spacing: 0.5px; */
  @media (--viewportMedium) {
    padding: 0;
  }
}
.topTextcentered {
  max-width: 1000px;
  
  margin: 0 auto;
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: var(--fontWeightSemiBold);
  font-size: 30px;
  line-height: 35px;
  text-align: center;
  color: var(--burgundy);
  padding: 0 20px;
  /* letter-spacing: 0.5px; */

  @media screen and (max-width: 768px) {    
    padding: 20px;
  }
}

.box {
  position: relative;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background: var(--blue-2); */
  /* background-blend-mode: screen; */
  /* opacity: 0.75; */

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.overlay:hover {
  /* background: var(--burgundy-2); */
}
.overlayTilte {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: var(--fontWeightSemiBold);
  font-size: 25px;
  line-height: 33px;
  text-align: center;
  color: #FFFFFF;
  margin: 0;
}
.overlaySubTitle {
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: var(--fontWeight);
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #FFFFFF;
  margin: 10px 0 0;
}
.overlayBtn {
  border: 2px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 100px;
  padding: 5px 50px;
  margin-top: 15px;
  cursor: pointer;

  background-color: #ffffff;
  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: var(--fontWeightSemiBold);
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #2B2F68;
  transition: all 0.5 ease-in-out;
}
.overlayBtn:hover {
  background: #ffffff;
  color: var(--burgundy);
}


.mainContainerInner {
  /* horizontal boxes */
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-areas:
    'box1 box2 box3'
    'box4 box5 box6';
  gap: 30px;
  margin: 0 auto;
  max-width: 1068px;
  padding: 0 20px;

  @media (min-width: 768px) and (max-width: 1268px) {
  
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-areas:
      'box1 box2'
      'box3 box4'
      'box5 box6';
  }

  
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas:
      'box1'
      'box2'
      'box3'
      'box4'
      'box5'
      'box6';
  }


}
.newmainContainerInner {
  /* horizontal boxes */
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr;
  grid-template-areas:
    'box1 box2 box3'
    'box4 box5 box6';
  gap: 10px;
  margin: 0 auto;
  max-width: 1068px;

  @media (min-width: 768px) and (max-width: 1268px) {
  
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-template-areas:
      'box1 box2'
      'box3 box4'
      'box5 box6';
  }

  
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr ;
    grid-template-areas:
      'box1'
      'box2'
      'box3'
      'box4'
      'box5'
      'box6';
  }

}
.box1 {
  width: 100%;
  grid-area: box1;

  min-height: 250px;
  background: url('./box1.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.box2 {
  grid-area: box2;
  width: 100%;
  min-height: 250px;
  background: url('./box2.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
}
.box3 {
 /* Auto layout */

display: flex;
flex-direction: column;
align-items: center;
padding: 30px 20px;
gap: 30px;

width: 340px;
height: 212px;

/* Ellipse 3 */

background: #2B2F68;

/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 0;
}
.box4 {
 /* Auto layout */

display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
padding: 0px 20px;
gap: 30px;

width: 270px;
height: 432px;

background:  url('./scott-warman-h4AGlo55tTA-unsplash\ 1.png'),linear-gradient(
  rgba(43, 47, 104, 0.74), 
  rgba(43, 47, 104, 0.74)
);
background-repeat: no-repeat;
background-size: cover;
background-position: center center;
background-blend-mode: overlay;



/* Inside auto layout */

flex: none;
order: 1;
flex-grow: 0;


}
.box5 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;
  gap: 30px;
  
  width: 270px;
  height: 432px;
  
  background:  url('./seocndcard.png'),linear-gradient(
    rgba(43, 47, 104, 0.74), 
    rgba(43, 47, 104, 0.74)
  );
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-blend-mode: overlay;
  
  
  
  /* Inside auto layout */
  
  flex: none;
  order: 1;
  flex-grow: 0;
}
.box6 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;
  gap: 30px;
  
  width: 270px;
  height: 432px;
  
  background:  url('./thirdcard.png'),linear-gradient(
    rgba(43, 47, 104, 0.74), 
    rgba(43, 47, 104, 0.74)
  );
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-blend-mode: overlay;
  
  
  
  /* Inside auto layout */
  
  flex: none;
  order: 1;
  flex-grow: 0;
}

.box7 {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0px 20px;
  gap: 30px;
  
  width: 270px;
  height: 432px;
  
  background:  url('./4thcard.png'),linear-gradient(
    rgba(43, 47, 104, 0.74), 
    rgba(43, 47, 104, 0.74)
  );
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  background-blend-mode: overlay;
  
  
  
  /* Inside auto layout */
  
  flex: none;
  order: 1;
  flex-grow: 0;
}

.boxContainer {

}

/* Square corners for the last section if it's even */
.section:nth-of-type(2n):last-of-type {
  @media (--viewportMedium) {
    border-radius: 4px 4px 0 0;
  }
}

/* Every other section has a light background */
.section:nth-of-type(2n) {
  background-color: var(--matterColorLight);
  @media (--viewportMedium) {
    border-radius: 4px;
  }
}

.sectionContent {
  margin: var(--LandingPage_sectionMarginTop) 24px 51px 24px;

  @media (--viewportMedium) {
    max-width: 100%;
    margin: var(--LandingPage_sectionMarginTopMedium) 24px 60px 24px;
  }

  @media (--viewportLarge) {
    max-width: 1128px;
    padding: 0 36px 0 36px;
    margin: var(--LandingPage_sectionMarginTopLarge) auto 93px auto;
  }

  @media (--viewportXLarge) {
    max-width: 1056px;
    padding: 0;
  }
}

.sectionContentFirstChild {
  composes: sectionContent;
  margin-top: 3vh;
}

/* A bar on top of light sections */
.section:nth-of-type(2n) .sectionContent::before {
  background: var(--marketplaceColor);
  content: '';
  display: block;
  width: 109px;
  height: 6px;

  /* Place the bar on top of .sectionContent top margin */
  position: relative;
  top: calc(-1 * var(--LandingPage_sectionMarginTop));

  @media (--viewportMedium) {
    width: 192px;
    height: 8px;
    top: calc(-1 * var(--LandingPage_sectionMarginTopMedium));
  }

  @media (--viewportLarge) {
    top: calc(-1 * var(--LandingPage_sectionMarginTopLarge));
  }
}

.newOverlayBtn {
  border: 2px solid #FFFFFF;
  box-sizing: border-box;
  border-radius: 100px;
  padding: 10px 20px;
  margin-top: 15px;
  cursor: pointer;

  font-family: var(--fontFamily);
  font-style: normal;
  font-weight: var(--fontWeightSemiBold);
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #FFFFFF;
  transition: all 0.5 ease-in-out;
}
.newOverlayBtn:hover {
  background: #ffffff;
  color: var(--burgundy);
}
