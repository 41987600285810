@import '../../../styles/customMediaQueries.css';
@import '../../../styles/propertySets.css';

/* Animation for the hero elements */

@keyframes animationTitle {
  0% {
    visibility: hidden;
    opacity: 0;
    top: -20px;
  }

  100% {
    visibility: visible;
    opacity: 1;
    top: 0;
  }
}

/* Shared animation rules */
.animation {
  animation-name: animationTitle;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  -webkit-animation-fill-mode: forwards;

  visibility: hidden;
  opacity: 1;
  position: relative;
}

.root {
  /* Gradient direction and overlaying the black color on top of the image for better readability */
  background: linear-gradient(-45deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.6)),
    url('../../../assets/background-1440.jpg');
  background-color: var(--matterColor);
  background-size: cover;
  background-position: center center;

  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;

  @media (--viewportLarge) {
    border-radius: 40px;
    border: solid 36px var(--matterColorBright);
  }
}

.heroContent {
  margin: 0 24px 0 24px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (--viewportMedium) {
    margin: 0 7.5vw 0 7.5vw;
  }

  /* Special viewport for adjusting the heroContent's placement */

  @media only screen and (min-width: 1025px) and (max-width: 1366px) {
    padding: 0 36px 0 36px;
  }

  @media only screen and (min-width: 1367px) {
    margin: 0 auto;
    max-width: calc(1052px + 36px + 36px);
    width: 100%;
    padding: 0 36px 0 36px;
  }
}

.heroMainTitle {
  @apply --marketplaceHeroTitleFontStyles;
  text-align: center;
  color: var(--white);
  margin-bottom: 0;

  composes: animation;
  animation-delay: 0.5s;

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
    margin-bottom: 0;
  }
}

.bottlesGroup {
  padding: 33px 0;
  composes: animation;
  animation-delay: 0.65s;
}

.heroSubTitle {
  @apply --marketplaceH4FontStyles;

  color: var(--white);
  margin: 0 0 15px 0;

  font-size: 20px;
  font-family: 'Source Sans Pro';
  line-height: 28px;
  font-weight: 600;
  composes: animation;
  animation-delay: 0.8s;

  @media (--viewportMedium) {
    max-width: var(--SectionHero_desktopTitleMaxWidth);
    margin: 0 0 20px 0;
  }
}

.searchBarWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-bottom: 40px;

  composes: animation;
  animation-delay: 0.95s;

  @media (--viewportMedium) {
    width: 540px;
    padding-bottom: 10px;
  }
}

.searchIcon {
  position: absolute;
  top: 11px;
  right: 11px;
  cursor: pointer;
}

.searchBar {
  width: 100%;
  height: 50px;
  background: var(--white);
  padding: 0 20px;
  border-radius: 5px;
  border-bottom: none;

  @media (--viewportMedium) {
    width: 540px;
    height: 50px;
  }
}

.searchBar::placeholder {
  color: var(--black);
  font-style: italic;
  font-size: 14px;

  @media (--viewportMedium) {
    font-size: 16px;
  }
}


.heroButton {
  @apply --marketplaceButtonStyles;
  composes: animation;

  animation-delay: 0.95s;

  @media (--viewportMedium) {
    display: block;
    width: 260px;
  }
}
